import ConsenterLibrary from '@utopia-de/consenter';

const cmp_utopia_de = {
  data: {
    customVendorIds: {
      ANALYTICS: '5e542b3a4cd8884eb41b5a72',
      CUSTOM_SEARCH: '5f1028a451b3726bd49182e7'
    },
    functionToPurpose: {
      PUSH_ENGAGE: '1',
      WRITE_COOKIES: '1',
      CREATE_PERSONALIZED_ADS_PROFILE: '3'
    }
  },
  filter: {
    customVendors: function(vendorConsents) {
      return vendorConsents.consentedVendors.filter( function( vendor ) {
        return vendorConsents.grants[vendor._id].vendorGrant === true;
      } );
    },
    purHandling: function() {
      if ( window._sp_.config.targetingParams.acps === 'true' ) {
        return {
          websitePurposes: { 1: true },
          tagManagerPurposes: { 2: false, 3: false, 6: false }
        };
      }
      return null;
    }
  }
};

window.consenter = new ConsenterLibrary.Consenter();
window.MY_CONSENT_CALLBACK = window.consenter.start;

window.tcfApiConsentAdapter = new ConsenterLibrary.TcfApiAdapter( window, cmp_utopia_de);
window.purHandler = new ConsenterLibrary.PurHandler();
